import ViewedOffersSection from '../ViewedOfferSection/ViewedOffersSection';
import CartContext from '../../../provider/CartProvider';
import { useContext } from 'react';
import AddedOfferSection from '../AddedOfferSection/AddedOfferSection';
import { handleIsOfferInCart } from './BseeCartComponentUtils';

const BseeCartComponent = () => {
    const { viewedOffers, addedOffers } = useContext(CartContext);
    const addedOffer = addedOffers || [];

    return (
        <div className={'offer-section'}>
            <AddedOfferSection addedOffer={addedOffer} />
            <ViewedOffersSection offers={viewedOffers} isOfferInCart={handleIsOfferInCart(addedOffers)} />
        </div>
    );
};

export default BseeCartComponent;
