import { getCustomerInfo } from '../../shopping-carts/bsee-cart/GetCustomerInfo';
import { getSessionIdFromCookie, getVisitorSessionId } from './../../utils/utility';
import { bseeCartDefaultEndpoint, bseeCustomerCacheEndpoint } from './Utilities/ApiUtils';
import { db } from "../../shopping-carts/bsee-cart/BseePscDB";
const useApiHandlers = () => {
    const getInitOffers = async () => {
        const customer = getCustomerInfo();
        const sessionId = customer?.sessionId ? customer?.sessionId : getSessionIdFromCookie()


        const url = customer?.isLocalized ?
            `${bseeCartDefaultEndpoint()}/${customer?.cookieValue}/${sessionId}/offers` :
            `${bseeCartDefaultEndpoint()}/${customer?.cookieValue}/geo/${customer?.marketId}/offers`;

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${customer?.token}`,
                'tracking-id': getVisitorSessionId() ?? ''
            }

        });
        if (!response.ok) {
            return;

        }
        const res = await response.json();

        if (res) {
            clearAndUpdateViewedOffers(res.data)
        }
    };

    const clearAndUpdateViewedOffers = async (data: any[]) => {
        await db.pscViewed.clear().then(() => {
            data.forEach(item => {
                db.pscViewed.add(item);
            })
        })
    }

    const clearCustomercache = async (sessionId: any) => {
        const customer = getCustomerInfo();

        const url = `${bseeCustomerCacheEndpoint()}${sessionId}`;

        const response = await fetch(url, {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Authorization': `Bearer ${customer?.token}`,
                'Content-Type': 'application/json',
                'tracking-id': getVisitorSessionId() ?? ''
            }
        });
        if (!response.ok) {
            // prettier-ignore
            console.log(`%c MESSAGE`, 'background: #0047ab; color: #fff; padding: 3px;', response); // eslint-disable-line

        }
    }

    const reduceOffers = (offer: any[]) => {
        return offer.map(item => {
            return { offer: { id: item.offer.id }, timeStamp: item.timeStamp }
        })
    }

    const postViewedOffers = async (offers: any) => {
        const customer = getCustomerInfo();
        const url = `${bseeCartDefaultEndpoint()}/offers`;

        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Authorization': `Bearer ${customer?.token}`,
                'Content-Type': 'application/json',
                'tracking-id': getVisitorSessionId() ?? ''
            },
            body: JSON.stringify({
                'action': customer?.isLocalized ? 'Localized' : 'Add',
                'marketId': `${customer?.marketId}`,
                'sessionId': `${customer?.sessionId}`,
                'visitorId': `${customer?.cookieValue}`,
                'viewedOffers': reduceOffers(offers)
            })
        });
        if (!response.ok) {
            // prettier-ignore
            console.log(`%c MESSAGE`, 'background: #0047ab; color: #fff; padding: 3px;', response); // eslint-disable-line

        }
        const data = await response.json();

        if (data) {
            return data;
        }
    };

    return { getInitOffers, postViewedOffers, clearCustomercache } as const;
}

export default useApiHandlers;




