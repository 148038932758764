import styled from 'styled-components';
const StyledShopLinks = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    border-top: 1px solid #e5e5e5;
    a {
        border-radius : 5px;
    }
    p {
        margin: 16px 0;
        font-family: lato;
        font-size: 14px
        color: var(--psc-greyDk);
    }
`;
export default StyledShopLinks;