// @ts-nocheck
import { getCustomerInfo } from "../shopping-carts/bsee-cart/GetCustomerInfo";
import { getVisitorSessionId } from './utility';

let loggingDom = document.querySelector('[data-redux-id="logging"]');

loggingDom = loggingDom ? JSON.parse(loggingDom.text) : null;

export const errorLogMessageModel = {
    applicationId: loggingDom?.applicationId,
    trackingId: loggingDom?.trackingId,
    message: '',
    level: 'Info',
    logType: 'message',
    severity: 'Low',
    appType: 'ClientApp',
    appVersion: loggingDom?.appVersion,
    host: loggingDom?.host,
    customerId: loggingDom?.customerId,
    createDateUtc: '',
    properties: {
        clientCodeBase: 'bsee-cart',
        location: '',
        componentName: '',
        messageType: 'JS Error'
    }
};

export const handleLogging = (data: any) => {
    const endpoint = loggingDom.loggingServiceUri;

    const customerInfo = getCustomerInfo();

    console.log(data, endpoint);

    try {
        if (data) {
            const logMessageModel = errorLogMessageModel;

            logMessageModel.createDateUtc = new Date().toISOString();
            logMessageModel.message = data.errorMessage;
            logMessageModel.properties.errorStack = data.errorStack;
            logMessageModel.properties.location = data.location;
            logMessageModel.properties.componentName = data.name;
            logMessageModel.properties.configurationKeys =
                data.configurationKeys;
            if (data.worker) {
                logMessageModel.properties.worker = data.worker;
            }
            if (data.success) {
                logMessageModel.properties.messageType = 'JS Success';
                logMessageModel.message = data.message;
            }
            const options = {
                data: {
                    events: [{ ...logMessageModel }]
                }
            };

            if (window.location.hostname !== 'localhost') {
                options.headers = {
                    authorization: `Bearer ${customerInfo?.token}`,
                    'tracking-id': getVisitorSessionId(),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                };
            }

            (async () => {
                await fetch(endpoint, {
                    method: 'POST',
                    headers: {
                        ...options.headers
                    },
                    body: JSON.stringify(options.data)
                });
            })();
        } else {
            throw new Error('No Arguments were passed in to log message.');
        }
    } catch (err) {
        console.log(err); // eslint-disable-line
    }
}
