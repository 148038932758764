/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { cookieFunction, getCookie } from '../../utils/utility';
import { handleLogging } from "../../utils/logging";
import { cookieName } from '../../utils/constants';

import { db } from "../../shopping-carts/bsee-cart/BseePscDB";
import { useLiveQuery } from "dexie-react-hooks";
import PscAdded from './PscAdded';

const getAllAddedOffers = async () => {
    const addedOffers = useLiveQuery(async () => await db.pscAdded.toArray());
    return addedOffers;
}

const addOfferDetails = () => {
    const [addedOffers, setAddedOffers] = useState<PscAdded[]>([]);

    const updateAddedOffers = async (offer: { id: string }): Promise<void> => {

        const formattedOffer = {
            offer: offer,
            timeStamp: new Date().toISOString()
        };
        cookieFunction();

        setTimeout(async () => {
            await db.pscAdded.clear().then(async () => {
                await db.pscAdded.add(formattedOffer)
                    .then(async () => {
                        await db.pscAdded.toArray()
                            .then((items) => {
                                handleLogging({
                                    success: true,
                                    message: 'success! offer added to cart'
                                });
                                return setAddedOffers(items);
                            })
                            .catch((e) => handleLogging(e));
                    })
                    .catch((e) => handleLogging(e));
            });

        }, 200);

    };
    const initAddedOffers = async () => {
        const cartCookie = getCookie(cookieName)

        await db.pscAdded.toArray().then((items) => {

            if (cartCookie) {
                setAddedOffers(items);
            } else {
                clearAddedOffers();
                setAddedOffers([]);
            }

        }).catch((e) => handleLogging(e));

    };

    const clearAddedOffers = () => {
        setAddedOffers([]);
        db.pscAdded.clear();
    }


    return { updateAddedOffers, initAddedOffers, addedOffers, clearAddedOffers, getAllAddedOffers } as const;
};
export default addOfferDetails;
