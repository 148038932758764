import { useState } from 'react';
import { cookieFunction, getCookie } from '../../utils/utility';
import useApiHandlers from '../UseApiHandlers/UseApiHandlers';
import { cookieName } from '../../utils/constants';

import { db } from "../../shopping-carts/bsee-cart/BseePscDB";
import PscViewed from './PscViewed';


const useOfferDetails = () => {
    const [viewedOffers, setViewedOffers] = useState<PscViewed[]>([]);
    const { postViewedOffers } = useApiHandlers();

    const handleAddItem = async (offer: { offer: { id: string }; timeStamp: string; }) => {
        await db.transaction('rw', db.pscAdded, db.pscViewed, async () => {
            await db.pscViewed.add(offer).then(async () => {
                await db.pscViewed.toArray().then((items) => {
                    postViewedOffers(items);
                    return setViewedOffers(items);
                });
            })
        })
    }

    const getAllViewedOffers = async () => {
        let viewedItems;

        await db.pscViewed.toArray().then((items) => {
            viewedItems = items
        });

        return viewedItems
    }

    const checkAndDeleteOfferFromViewed = (offer: { id: any; }) => {
        db.pscViewed.each(async item => {
            if (item) {
                if (item?.offer?.id === offer.id) {
                    await deleteSingleViewedOffer(item.timeStamp);
                }
            }
        })
    }

    const updateForAddedOffers = async (offer: { id: string; }) => {
        await db.transaction('rw', db.pscAdded, db.pscViewed, async () => {
            checkAndDeleteOfferFromViewed(offer);
            await db.pscAdded.each(async item => {
                if (item) {
                    if (item?.offer?.id === offer.id) {
                        await deleteSingleViewedOffer(item?.timeStamp)
                    } else {
                        handleAddItem(item);
                    }
                }
            })
        })
    }

    const deleteSingleViewedOffer = async (timestamp: any): Promise<void> => {
        return await db.pscViewed.delete(timestamp).then(async () => {
            await db.pscViewed.toArray().then((items) => {
                postViewedOffers(items);
                setViewedOffers(items);
            })
        })
    }

    const checkForAddedOffers = async (offer: { id: any; }) => {
        let shouldContinue = true;
        await db.pscAdded.each(async item => {;
            if (item) {
                if (item?.offer?.id === offer.id) {
                    shouldContinue = false;
                }
            }
        })
        return shouldContinue;
    }

    const updateViewedItems = async (offer: { id: any; }, added: boolean = false, defaultCheck: boolean = true) => {
        const callSetViewedFunction = await checkForAddedOffers(offer);
        const formattedOffer = {
            offer: offer,
            timeStamp: new Date().toISOString()
        };
        cookieFunction();

        if (added) {
            updateForAddedOffers(offer)
            return;
        }

        if (!callSetViewedFunction && defaultCheck) {
            return;
        }

        await db.transaction('rw', db.pscAdded, db.pscViewed , async () => {
            await db.pscViewed.each(async item => {
                if (item) {
                    if (item?.offer?.id === offer.id) {
                        await deleteSingleViewedOffer(item?.timeStamp).then(() => {
                            handleAddItem(formattedOffer);
                        })
                    }
                }
            }).then(() =>{
                handleAddItem(formattedOffer);
            })
        })
    };

    const initViewedOffers = async () => {
        const cartCookie = getCookie(cookieName)

        await db.pscViewed.toArray().then((items) => {
            if (cartCookie) {
                setViewedOffers(items);
            } else {
                clearViewedOffers()
            }
        });
    };

    const clearViewedOffers = () => {
        setViewedOffers([]);
        db.pscViewed.clear();
    }

    return { updateViewedItems, initViewedOffers, viewedOffers, clearViewedOffers, deleteSingleViewedOffer, getAllViewedOffers } as const;
};

export default useOfferDetails;
