
import { cookieName } from '../../utils/constants';
import { getCookie, getVisitorIdFromCookie, isLocalHost } from '../../utils/utility';
import { getSessionIdFromCookie, getMarketIdFromCookie } from './../../utils/utility';

export const getCustomerInfo = () => {
    interface TestI {
        checkAvailability: {
            sessionId: string
        },
        credentialStore: {
            cimaEcom: {
                token: {
                    accessToken: string
                }
            }
        }
    }

    // SMALL CHECK TO ALLOW SB TESTING
    let storeData;
    let sessionId;
    let fullMarketId
    const pscCookie = getCookie(cookieName);

    if (!isLocalHost()) {
        storeData = window.store.getState() as TestI
    } else {
        storeData = window.parent.store as unknown as TestI
    }

    if (storeData?.checkAvailability?.sessionId) {
        sessionId = storeData?.checkAvailability?.sessionId
    } else if (!storeData?.checkAvailability?.sessionId && pscCookie) {
        sessionId = getSessionIdFromCookie();
    }
    /**
     * The marketId is set from acquisition-ui codebase on a successful getMarketInfo call
     * and it will be removed on a full localization
     */
    const storedMarketId = window.sessionStorage.getItem('marketId');

    if (storedMarketId?.length) {
        fullMarketId = storedMarketId;
    } else if (pscCookie) {
        fullMarketId = getMarketIdFromCookie();
    }

    const cookieValue = getVisitorIdFromCookie();

    let store;
    if (storeData) {
        store = {
            marketId: fullMarketId,
            sessionId: sessionId,
            token: storeData?.credentialStore?.cimaEcom?.token?.accessToken,
            cookieValue: cookieValue,
            isLocalized: fullMarketId && fullMarketId !== "0" ? false : true
        };
    }

    return store;
};