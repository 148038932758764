import * as React from 'react';
import ReactDOM from 'react-dom'
import App from './app';
import { cartMountPoint } from './utils/constants';

const axeConfig = {
    reporter: 'v2',
    rules: [
        {
            id: 'excludeHidden',
            enabled: true
        }
    ]
};

if (process.env.NODE_ENV !== 'production') { // eslint-disable-line
    const axe = require('@axe-core/react');

    axe(React, ReactDOM, 7000, axeConfig);
}

ReactDOM.render(
    <App />,
    document.getElementById(cartMountPoint),
)
