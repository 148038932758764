export const urls = {
    'shop': '/shop/offers',
    'mobile': '/learn/mobile',
    'prod': 'https://business.comcast.com',
    'cmbCart': '/shop/mobile/cart'
};

export const maxOffers = 4;
export const cookieName = 'cb_psc';
export const cookieExpires = 14;
export const cartMountPoint = 'bsd-nav-cart-icon';
export const bseeAppTypes = ['BusinessServicesEcommerceExperience']
export const cbmAppTypes = ['ComcastBusinessMobileLearn', 'ComcastBusinessMobileBuy']
export const renderCartApp = true;
export const geoLocalId = '00000000-0000-0000-0000-000000000000'
export const topHeader = '0px';


export const cookieNames = {
    SC: 'SC',
    visitorSessionId: 'RC.USID',
    SCMSID: 'SCMS_ID',
    extendedSession: 'EXTENDED_SESSION',
    BPO: 'BPOCookie'
};

export const eventActions = {
    shopServices: 'shop services',
    shopMobileForBusiness: 'shop mobile for business',
    empty: 'empty',
    full: 'full',
    viewPlans: 'VIEW ALL PLANS',
    offerDetail: 'offer details',
    poiAccordion: 'pricing & other Info accordion',
    poiLink: 'pricing & other Info'
}