import { FC, useContext, useRef } from 'react';
import CartPreviewStyles from './CartContainerStyles';
import CartOverlayStyles from './CartOverlayStyles';
import CartContext from '../../../provider/CartProvider';
import CartHeader from '../../Molecules/CartHeader/CartHeader';
import { WithChildrenI } from '../../../globalInterface';

const CartContainer: FC<WithChildrenI> = ({ children }) => {
    const { isCartOpen, toggleCart } = useContext(CartContext);
    const cartRef = useRef<HTMLElement>(null);

    return (
        <>
            {isCartOpen && <CartOverlayStyles onClick={toggleCart} />}
            <CartPreviewStyles aria-hidden={!isCartOpen} role="dialog" ref={cartRef}>
                <CartHeader />
                {children}
            </CartPreviewStyles >
        </>

    );
};

export default CartContainer;
