import styled from 'styled-components';
import { ButtonI } from '../../Atoms/Button/ButtonInterface';
import StyledButton from '../../Atoms/Button/StyledButton';

type ButtonProps = ButtonI & {
    isOfferViewedorAdded: boolean;
};

const StyledCartButton = styled(StyledButton) <ButtonProps>`
    position: relative;
    z-index: 10;
    border-radius: 0;
    padding: 0 22px 0 8px;

    @media(min-width: 1024px) {
        border-left: 1px solid #45587b;
        padding: 2px 22px;
    }
    

    &:before {
        position: absolute;
        display: ${(props) => (props.isOfferViewedorAdded ? 'block' : 'none')};
        content: '';
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background-color: #3d81ff;
        right: 16px;
        top: -4px;

        @media(min-width: 1024px) {
            top: 0;
        }
    }

    svg {
        width: 20px;
        height: 20px;

        @media(min-width: 1024px) {
            width: 24px;
            height: 24px;
        }
    }
`;

export default StyledCartButton;
