import styled from 'styled-components';
const StyledCartOverlayPreview = styled.div`
   position: fixed;
    top: 0;
    width: 100%;
    background: #373737;
    height: 100%;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    z-index: 9999;
    opacity: 0.7;
`;
export default StyledCartOverlayPreview;
