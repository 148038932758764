import { useContext, useEffect, createRef } from 'react';
import Button from '../../Atoms/Button/Button';
import closeIcon from '../../../assets/closeIcon';
import CartContext from '../../../provider/CartProvider';

const CartHeader = () => {
    const { toggleCart, isCartOpen } = useContext(CartContext);
    const ref = createRef<HTMLButtonElement>()

    useEffect(() => {
        isCartOpen && ref?.current?.focus()
    }, [toggleCart])

    return (
        <div className="psc-cart-main">
            <h3 id="cart-heading">Cart Preview</h3>
            <Button accessibleText="close cart" onClick={(evt) => {
                // @ts-ignore
                toggleCart(evt.target)
            }} config="icon" ref={ref}>
                {closeIcon()}
            </Button>
        </div>
    );
};

export default CartHeader;
