/* istanbul ignore file */
import { createContext, FC } from 'react';
import { PortableCartI } from './Interface';
import useShoppingCart from '../library/UseToggleCart/UseToggleCart';
import useOfferDetails from '../library/UseViewedOffer/UseViewedOffer';
import addOfferDetails from '../library/UseAddedOffer/UseAddedOffer';
import { WithChildrenI } from '../globalInterface';

const PortableCartContext = createContext<PortableCartI>({
    isCartOpen: false,
    toggleCart() { },
    viewedOffers: [],
    updateViewedItems() { },
    initViewedOffers() { },
    addedOffers: null,
    updateAddedOffers() { },
    initAddedOffers() { },
    clearViewedOffers() { },
    clearAddedOffers() { },
    deleteSingleViewedOffer() { }
});

export const PortableCartProvider: FC<WithChildrenI> = ({ children }) => {
    const { isCartOpen, toggleCart } = useShoppingCart(false);
    const { updateViewedItems, initViewedOffers, viewedOffers, clearViewedOffers, deleteSingleViewedOffer } =
        useOfferDetails();
    const { updateAddedOffers, initAddedOffers, addedOffers, clearAddedOffers } =
        addOfferDetails();

    return (
        <PortableCartContext.Provider
            value={{
                isCartOpen,
                toggleCart,
                updateViewedItems,
                viewedOffers,
                initViewedOffers,
                updateAddedOffers,
                addedOffers,
                initAddedOffers,
                clearViewedOffers,
                clearAddedOffers,
                deleteSingleViewedOffer
            }}
        >
            {children}
        </PortableCartContext.Provider>
    );
};

export default PortableCartContext;
