import { useEffect, useContext } from 'react';
import BseeCartComponent from '../../components/Containers/BseeCartComponent/BseeCartComponent';
import ctx from '../../provider/CartProvider';
import useLocalize from "../../library/UseLocalize/UseLocalize";

const BseeCart = () => {
    const { updateViewedItems, initViewedOffers, updateAddedOffers, initAddedOffers } = useContext(ctx);
    const { checkSessionCookie, updateCookie } = useLocalize();

    useEffect(() => {
        initViewedOffers();
        initAddedOffers();

        document.addEventListener('psc_viewed', (e) => {
            updateViewedItems(e.detail);
        }, true);

        document.addEventListener('localized_success', (e) => {
            checkSessionCookie(e);
            updateCookie(e);
            initAddedOffers();
        }, true);

        document.addEventListener('psc_added', (e) => {
            updateViewedItems(e.detail, true);
            updateAddedOffers(e.detail);
        }, true);

        return () => {
            document.removeEventListener('psc_viewed', (e) => updateViewedItems(e.detail), true);
            document.removeEventListener('psc_added', (e) => updateAddedOffers(e.detail), true);
            document.removeEventListener('localized_success', (e) => {
                checkSessionCookie(e);
                updateCookie(e);
            }, true);
        };
    }, []);

    return (
        <>
            <BseeCartComponent />
        </>
    );
};

export default BseeCart;
