import StyledContainer from './EmptyCartContainerStyles';

const EmptyCartContainer = () => (
    <StyledContainer>
        <p>Your cart is empty</p>
        <p>
            but we&apos;re full of solutions.
        </p>
    </StyledContainer>
)

export default EmptyCartContainer;