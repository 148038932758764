import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
      --psc-blueLt: #3d81ff;
      --psc-blueMd: #0d62ff;
      --psc-blueDk: #0a4ecc;
      --psc-white: #fff;
      --psc-grey: #f5f5f5;
      --psc-greyDk: #3b3b3b;
      --psc-black: #000;
      --psc-white-alabaster: #f7f7f7
  }

  .psc-cart-open {
    overflow: hidden
  }
`;

export default GlobalStyle;
