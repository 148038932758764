import {
    useContext, FC, useEffect, useState, createRef
} from 'react';
import cartIcon from '../../../assets/shoppingCartIcon';
import CartContext from '../../../provider/CartProvider';
import { urls, cbmAppTypes } from '../../../utils/constants';
import { getOriginName } from '../../../utils/utility';
import StyledCartButton from '../../Molecules/CartButton/cartButtonStyles';

interface PropsI {
    appName: string
}
const CartButton: FC<PropsI> = ({ appName }) => {
    const {
        toggleCart, isCartOpen, viewedOffers, addedOffers,
    } = useContext(CartContext);
    const ref = createRef<HTMLButtonElement>();
    const [active, setActive] = useState(false)

    const [showIndicator, setShowIndicator] = useState({
        indicator: false,
        a11yText: 'open cart',
    });

    useEffect(() => {
        if ((viewedOffers.length || addedOffers?.length) && !cbmAppTypes.includes(appName)) {
            setShowIndicator({
                indicator: true,
                a11yText: 'items in cart',
            });
        } else {
            setShowIndicator({
                indicator: false,
                a11yText: 'open cart',
            });
        }
        if (cbmAppTypes.includes(appName)) {
            setShowIndicator({
                indicator: false,
                a11yText: 'opens cart in new page',
            });
        }
    }, [viewedOffers, addedOffers]);

    useEffect(() => {
        (!isCartOpen && active) && ref?.current?.focus()
    }, [toggleCart])

    const handleClick = (evt: { target: any }) => {
        !active && setActive(true)
        if (cbmAppTypes.includes(appName)) {
            window.location.href = `${getOriginName()}${urls.cmbCart}`;
        } else {
            // @ts-ignore:next-line
            toggleCart(evt.target);
        }
    };

    return (
        <StyledCartButton
            aria-describedby="tooltip-cart"
            aria-label={showIndicator.a11yText}
            onClick={handleClick}
            isOfferViewedorAdded={showIndicator.indicator}
            config="icon"
            ref={ref}
        >
            {cartIcon()}
        </StyledCartButton>
    );
};

export default CartButton;
