import styled from 'styled-components';

type CartPreviewProps = {
    ref: any;
};
const StyledCartPreview = styled.div<CartPreviewProps>`
    width: 100%;
    top: 0;
    margin: 0;
    font-size: 18px;
    box-shadow: 0 2px 16px 0 rgba(33, 43, 54, 0.08);
    position: fixed;
    right: 0;
    z-index: 10000;
    transform: translateX(100%);
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    word-break:break-word;
    background-color: var( --psc-white);
    height: 100%;

    .offer-section {
        height: 95vh;
        overflow: auto
    }

    .psc-cart-view-all-offers {
        position: sticky;
        bottom: 30px;
    }

    @media (min-width: 481px) {
        height: 100px;
        width: 400px;
        min-height: 100%;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    &[aria-hidden='false'] {
        transform: translateX(0%);
    }

    h3 {
        margin: 0;
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 800;
    }

    .psc-cart-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        border-bottom: 1px solid #e6e6e6;

        #icon-close {
            width: 16px;
            height: 16px;
        }

        button{
            padding: 14px;
        }
    }

    .psc-cart-empty-state {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        p {
            margin: 0;
        }
        .psc-cart-empty-sub-heading {
            font-weight: 700;
            margin-bottom: 15px;
        }
        .psc-cart-empty-sub-message {
            color: #757575;
        }
    }
    .psc-cart-recently-viewed {
        background-color: var(--psc-white-alabaster);
        padding: 24px;
        font-weight: normal;
        padding-bottom: 50px;
        margin-bottom: 40px;

        .psc-offer-card {
            margin-bottom: 24px;
        }
    }

    .psc-cart-added {
        background-color: white;
        padding: 20px;
    }

    .psc-cart-checkout {
        padding: 24px;

        li {
            margin-top:0;
        }
}
`;
export default StyledCartPreview;
