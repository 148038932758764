import { forwardRef } from 'react';
import { ButtonI } from './ButtonInterface';
import StyledButton from './StyledButton';

const Button = forwardRef<HTMLButtonElement, ButtonI>((props, ref) => {
    const { accessibleText, isExpanded, onClick, config, classes, testId, children, id } = props;
    return (
        <StyledButton
            aria-label={accessibleText}
            aria-expanded={isExpanded}
            onClick={onClick}
            config={config}
            className={classes}
            data-testid={testId}
            ref={ref}
            id={id}
        >
            {children}
        </StyledButton>
    )
})

export default Button;
