import { FC } from 'react';
import { OfferCardI } from './OfferCardInterface';
import StyledOfferCard from './OfferCardStyles';

import OfferCardHeading from './Component/OfferCardHeading';
import OfferFeatureList from './Component/OfferFeatureList';
import OfferCardAction from './Component/OfferCardAction';

const OfferCard: FC<OfferCardI> = ({ offer, isOfferInCart, isCheckout }
) => {
    return (
        <StyledOfferCard>
            <OfferCardHeading offer={offer} />
            <OfferFeatureList offer={offer} />
            <OfferCardAction offer={offer} isOfferInCart={isOfferInCart}
                isCheckout={isCheckout} />
        </StyledOfferCard>
    );
};
export default OfferCard;
