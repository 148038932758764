import StyledButton from "../../Atoms/Button/StyledButton";
import { handleCtaCopy, handleAnalyticsOfferLocation } from "../OfferCard/OfferCardUtils";
import { FC } from "react";
import addOfferToCartHandler from './Utility/AddUtil';
import { AddOfferToCartButtonI } from './Interface';
import { eventActions } from '../../../utils/constants';
import useAnalytics from "../../../library/UseAnalytics/UseAnalytics";

const AddOfferToCartButton: FC<AddOfferToCartButtonI> = ({ offer, isOfferInCart, isCheckout }) => {
    const { handleAddToCart } = addOfferToCartHandler()
    const { clickTrackingAnalytics } = useAnalytics()

    const handleButton = (evt: { target: any; }) => {

        const data = {
            productId: offer.id,
            location: handleAnalyticsOfferLocation(isOfferInCart, offer, isCheckout),
            action: handleCtaCopy(isOfferInCart, offer, isCheckout).toLowerCase(),
            container: eventActions.full
        }

        clickTrackingAnalytics(evt.target, data, true)

        handleAddToCart(offer, isCheckout)
    };

    return (
        <StyledButton
            onClick={handleButton}
            config={isCheckout ? 'primary' : 'ghost'}
        >
            {handleCtaCopy(isOfferInCart, offer, isCheckout)}
        </StyledButton>
    );
};

export default AddOfferToCartButton;
