import StyledViewAllOffersLink from './StyledViewAllOffersLink';
import Link from '../../Atoms/Link/Link';
import { getOriginName } from '../../../utils/utility';
import { eventActions, urls } from '../../../utils/constants';

const ViewAllOffersLink = () => {
    const shop = getOriginName() + urls.shop;
    return (
        <StyledViewAllOffersLink>
            <Link to={shop} config='anchorLink' size='sm' track={{ action: eventActions.viewPlans, container: eventActions.full }}>
                view all plans
            </Link>
        </StyledViewAllOffersLink>
    );
};

export default ViewAllOffersLink;
