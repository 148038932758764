import { FC } from 'react';
import { LinkI } from './LinkInterface';
import StyledLink from './StyledLink';
import useAnalytics from '../../../library/UseAnalytics/UseAnalytics';
const Link: FC<LinkI> = ({
    children,
    to,
    config = 'link',
    size,
    color = 'white',
    track,
}) => {

    const { clickTrackingAnalytics } = useAnalytics()

    const handleClick = (evt: { target: any; }) => {
        if (!track) {
            return
        }
        clickTrackingAnalytics(evt.target, track, false)
    }

    return (
        <StyledLink href={to} config={config} size={size} color={color} onClick={handleClick}>
            {children}
        </StyledLink>
    );
}

export default Link;
