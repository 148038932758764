import styled from 'styled-components';

const StyledViewAllOffersLink = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 12px 30px;
    background-color: var(--psc-black);
    border-radius: 5px;
    position: fixed;
    top: 94vh;
    right: 20px;
    width: 89%;
    min-height: 40px;

    a {    
        text-decoration: none;
    }

    @media(min-width: 481px) {
    background-color: #edefef;
    border-top: 1px solid rgba(204, 204, 204);
    border-radius: 0;
    top: auto;
    right: auto;
    bottom: 0;
    width: 100%;
    padding: 15px 0;
    min-height: unset;

    a {
        color: var(--psc-black);
        text-decoration: underline;
    }
}
`;

export default StyledViewAllOffersLink;
