import styled from 'styled-components';
import { ButtonStyleI } from './ButtonInterface';

const StyledButton = styled.button<ButtonStyleI>`
    background: transparent;
    background-color: ${props => props.config === 'primary' && 'var(--psc-blueMd)'};
    border: ${props => props.config === 'ghost' ? 'solid 2px var(--psc-black)' : '0'};
    border-radius: 5px;
    color: ${props => props.config === 'primary' ? 'var(--psc-white)' : 'var(--psc-black)'};
    cursor: pointer;
    font-family: Montserrat;
    font-size: ${props => props.config !== 'link' ? '12px' : '16px'};
    font-weight: bold;
    letter-spacing: ${props => props.config !== 'link' ? '1.5px' : 'default'};
    min-height: ${props => (props.config !== 'link' && props.config !== 'icon') ? '40px' : 'unset'};
    padding: ${props => props.config === 'link' && '0' || props.config === 'ghost' && '10.5px 24px' || props.config == 'icon' && '0 22px' || '12.5px 24px'};
    text-align: ${props => (props.config !== 'link' && props.config !== 'icon') ? 'center' : 'left'};
    text-transform: ${props => (props.config === 'primary' || props.config === 'ghost') && 'uppercase'};
    width: ${props => (props.config !== 'link' && props.config !== 'icon') ? '100%' : 'auto'};

    span {
        font-family: lato;
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0.5px;
    }

    &:hover,
    &:focus {
        background-color: ${props =>
        props.config === 'primary' && 'var(--psc-blueDk)' ||
        props.config === 'ghost' && 'var(--psc-grey)'
    };
    }

    &:focus {
        border-color: ${props => props.config === 'ghost' && 'var(--psc-blueMd)'};
        background-color: ${props => props.config === 'primary' && 'var(--psc-blueLt)'};
        color: ${props => (props.config === 'primary' || props.config === 'link') && 'var(--psc-black)'};
        box-shadow: ${props => props.config === 'primary' && 'inset 0 0 0 3px var(--psc-black)'};
        box-shadow: ${props => props.config === 'link' && 'inset 0 0 0 3px var(--psc-blueMd)'};
        outline: ${props => props.config !== 'icon' && '0'};
    }

    @media(min-width: 481px) {
        padding: ${props => props.config === 'link' && '0' || props.config === 'ghost' && '11px 24px' || props.config == 'icon' && '0 22px' || '13px 24px'};
        min-height: unset;
    }

    #cartIcon {
        stroke: #fff;
    }
`;

export default StyledButton;
