import { useEffect, useState } from 'react';
import { focusTrap } from './../../utils/utility';
import useAddedOffers from '../UseAddedOffer/UseAddedOffer';
import useViewedOffers from '../UseViewedOffer/UseViewedOffer'
import useAnalytics from '../UseAnalytics/UseAnalytics';

const useShoppingCart = (defaultValue: boolean) => {
    const [isCartOpen, setIsCartOpen] = useState<boolean>(defaultValue);
    const { getAllAddedOffers } = useAddedOffers()
    const { getAllViewedOffers } = useViewedOffers()
    const { cartButtonAnalytics } = useAnalytics()
    let addedOffers: any;
    let viewedOffers: any;

    getAllAddedOffers().then(async (data) => {
        addedOffers = data;
    });

    getAllViewedOffers().then(async (data) => {
        viewedOffers = data
    })

    const toggleCart = (target: any) => {
        const isCartFull = addedOffers?.length > 0 || viewedOffers?.length > 0 ? 'full' : 'empty';

        setIsCartOpen(!isCartOpen)
        cartButtonAnalytics(target, isCartFull, !isCartOpen);
    };

    useEffect(() => {
        const root = document.documentElement;
        isCartOpen ? focusTrap(true, false) : focusTrap(true, true);

        if (!isCartOpen) {
            const offerSectionNode = document.querySelector('.offer-section');
            offerSectionNode && offerSectionNode.scrollTo(0, 0);
        }

        return isCartOpen
            ? root.classList.add('psc-cart-open')
            : root.classList.remove('psc-cart-open');
    }, [isCartOpen]);

    return { isCartOpen, toggleCart } as const;
};

export default useShoppingCart;
