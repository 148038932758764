import Dexie, { Table } from "dexie";
import PscAdded from "../../library/UseAddedOffer/PscAdded";
import PscViewed from "../../library/UseViewedOffer/PscViewed";

export class BseePscDB extends Dexie {
    pscAdded!: Table<PscAdded>;
    pscViewed!: Table<PscViewed>;
    constructor() {
        super("BseePscDB");
        this.version(1).stores({
            pscAdded: "timeStamp",
            pscViewed: "timeStamp",
        });
    }
}

export const db = new BseePscDB();
