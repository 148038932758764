import { useContext } from "react";
import ctx from '../../../../provider/CartProvider'
import { isGeoLocalized, getOriginName } from "../../../../utils/utility";

import { db } from '../../../../shopping-carts/bsee-cart/BseePscDB';

const addOfferToCartHandler = () => {
    const { toggleCart, deleteSingleViewedOffer, updateAddedOffers, updateViewedItems } = useContext(ctx);

    const checkAddedOffers = async (offer: { id: any; }) => {
            await db.pscAdded.each(async (item) => {
                if (item) {
                    if (item?.offer?.id !== offer.id) {
                        updateViewedItems(item?.offer, false, false)
                    } 
                }
            })
    }

    const checkDb = async (offer: { id: string; }) => {
        await db.transaction('rw', db.pscAdded, db.pscViewed, async () => {
            db.pscViewed.each(async (item) => {
                if (item) {
                    if (item?.offer?.id === offer.id) {
                        deleteSingleViewedOffer(item?.timeStamp)
                        await checkAddedOffers(offer)
                        updateAddedOffers(offer)
                    }
                }
            })
        })
        
    }

    const handleAddToCart = async (offer: { id: any; }, isCheckout: boolean | undefined) => {
        // const customer = getCustomerInfo()
        // !customer?.isLocalized && window.sessionStorage.setItem('psc_pending', 'true')
        toggleCart();
        const redirectUrl = "/shop/offers?showmessage=true"
        const formattedRedirect = redirectUrl.replace(/['"]+/g, '');
        const isGeoLocal = isGeoLocalized();
        const urlOrigin = getOriginName();
        window.sessionStorage.setItem('redirectUrl', formattedRedirect)

        await checkDb(offer);

        if (isGeoLocal) {
            window.location.href = `${urlOrigin}/shop/gateway?offerId=${offer.id}`
            return
        }

        if (isCheckout) {
            window.location.href = `${urlOrigin}/shop/buy`
        } else {
            if (window.store?.dispatch) {
                window.store?.dispatch({
                    type: 'offers/ADD_OFFER_TO_CART',
                    payload: offer.id
                })
            }
        }
    }


    return { handleAddToCart } as const

}

export default addOfferToCartHandler