import { useContext } from 'react';
import ctx from '../../provider/CartProvider';

const useAnalytics = () => {
    /**
     * Safely gets the page name from the window.digitalData object
     * @returns {string} The page name from data layer or an empty string
    */
    const getAnalyticsPageName = (): string => {
        if (window?.digitalData && window?.digitalData.page && window?.digitalData.page.pageInfo) {
            return window?.digitalData.page.pageInfo.pageName;
        }

        return '';
    };

    const { viewedOffers, addedOffers } = useContext(ctx);

    const cartButtonAnalytics = (target: any, cartStatus: string, cartOpen: boolean) => {
        const triggerAt = target ? target : document.getElementsByTagName('body')[0];
        const setAction = cartOpen ? 'cart icon' : 'close cart preview';

        triggerAt.dispatchEvent(
            new CustomEvent('c-tracking-log-dom', {
                detail: {
                    eventAction: setAction,
                    eventMethod: 'send-Event',
                    eventName: 'click',
                    eventPage: getAnalyticsPageName(),
                    container: [
                        `shopping cart: ${cartStatus}`
                    ],
                    type: 'click'
                },
                bubbles: true
            })
        );
    }

    const clickTrackingAnalytics = (target: any, data: any, addToCart: boolean) => {

        const triggerAt = target ? target : document.getElementsByName('body')[0];
        // @ts-ignore
        const cartStatus = (viewedOffers.length > 0 || addedOffers?.length > 0) ? 'full' : 'empty';

        triggerAt.dispatchEvent(new CustomEvent('c-tracking-log-dom', {
            detail: {
                eventAction: data.action,
                eventName: 'click',
                eventMethod: 'send-Event',
                eventPage: getAnalyticsPageName(),
                container: [
                    `shopping cart: ${cartStatus}`
                ],
                type: 'click'
            },
            bubbles: true
        }))

        if (addToCart) {
            triggerAt.dispatchEvent(new CustomEvent('c-tracking-log-dom', {
                detail: {
                    eventAction: 'Add to cart',
                    eventName: 'click',
                    eventMethod: 'cart-Addition',
                    eventPage: getAnalyticsPageName(),
                    product: {
                        productInfo: data.productId,
                    },
                    offerLocation: data.location,
                    type: 'click'
                },
                bubbles: true
            }))
        }
    }

    return { cartButtonAnalytics, clickTrackingAnalytics } as const;
}

export default useAnalytics;
