import { cookieName } from "../../utils/constants";
import { getSessionIdFromCookie, updateCookieId } from "../../utils/utility";
// import useApiHandlers from "../UseApiHandlers/UseApiHandlers";
import ctx from '../../provider/CartProvider'
import { useContext } from 'react';

const useLocalize = () => {
    const { clearViewedOffers, clearAddedOffers } = useContext(ctx)
    // const { clearCustomercache } = useApiHandlers()

    const checkSessionCookie = (e: { detail: string | undefined; }) => {
        const sessionIdFromCookie = getSessionIdFromCookie();

        if (sessionIdFromCookie !== e.detail) {
            // if (sessionIdFromCookie) {
            //     clearCustomercache(sessionIdFromCookie);
            // }
            clearViewedOffers();
            clearAddedOffers();
        }
    }
    const updateCookie = (e: { detail: string; }) => {
        updateCookieId(cookieName, e.detail)
    }

    return { updateCookie, checkSessionCookie } as const;
}

export default useLocalize;
