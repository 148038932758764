import { useState, FC } from 'react';
import { OfferI } from '../OfferCardInterface';
import { eventActions } from '../../../../utils/constants';
import Link from '../../../Atoms/Link/Link';
import Button from '../../../Atoms/Button/Button';
import { getOriginName, toUsdShorthand, setToHtml } from '../../../../utils/utility';
import useAnalytics from '../../../../library/UseAnalytics/UseAnalytics';


const OfferCardHeading: FC<OfferI> = ({ offer }) => {
    const [showPoi, setShowPoi] = useState(false);
    const { clickTrackingAnalytics } = useAnalytics()

    const handleAccordionClick = (evt: { target: any; }) => {
        clickTrackingAnalytics(evt.target, {
            action: eventActions.poiAccordion,
            container: eventActions.full
        }, false)
        setShowPoi(!showPoi)
    }
    return (
        <>
            <p className="badge" dangerouslySetInnerHTML={setToHtml(offer?.badgeText)} />
            <h3 className="name">
                <Link config='anchorLink' color='black' to={`${getOriginName()}/shop/offers/detail/${offer?.id}`} track={{ action: eventActions.offerDetail, container: eventActions.full }}>{offer?.name}</Link>
            </h3>
            <Button id="psc-poi-accordion" classes='poi' onClick={handleAccordionClick} isExpanded={showPoi} config="link" testId="poi-trigger">
                {offer?.isEPCEcoBillDiscountEnabled ? `$${offer?.epcEcoBillPrice}` : toUsdShorthand(offer?.price)}/mo
                {offer?.promotionalMonths && <span className="price-per-month"> for {offer?.promotionalMonths} mos</span>}
            </Button>
            {showPoi && <div className="pricing-desc">
                <p>
                    {offer?.contractDescription} {offer?.isEPCEcoBillDiscountEnabled && offer?.ecoBillOfferCardText}
                    <Link config='anchorLink' to={`${getOriginName()}/shop/offers/detail/${offer.id}/#pricing`} track={{ action: eventActions.poiLink, container: eventActions.full }}>Pricing & Other Info</Link>
                </p>
            </div>}
        </>
    )
}

export default OfferCardHeading;