const closeIcon = () => (
    <svg id="icon-close" width="16px" height="16px" viewBox="0 0 16 16">
        <g id="Icon-Sheet" mask="url(#mask-2)">
            <g transform="translate(-0.069946, -0.070312)" id="Combined-Shape" >
                <path d="M1.70710683,0.292893231 L8.0710001,6.65600061 L14.4350281,0.292893231 C14.7955121,-0.0675907433 15.3627442,-0.095320195 15.7550354,0.209704697 L15.8492413,0.292893231 C16.2397656,0.683417529 16.2397656,1.31658253 15.8492413,1.70710683 L9.48499966,8.0710001 L15.8492413,14.4350281 C16.2097253,14.7955121 16.2374552,15.3627442 15.9324303,15.7550354 L15.8492413,15.8492413 C15.458717,16.2397656 14.8255524,16.2397656 14.4350281,15.8492413 L8.0710001,9.48499966 L1.70710683,15.8492413 C1.34662285,16.2097253 0.779391706,16.2374552 0.387100518,15.9324303 L0.292893231,15.8492413 C-0.097631067,15.458717 -0.097631067,14.8255524 0.292893231,14.4350281 L6.65600061,8.0710001 L0.292893231,1.70710683 C-0.0675907433,1.34662285 -0.095320195,0.779391706 0.209704697,0.387100518 L0.292893231,0.292893231 C0.683417529,-0.097631067 1.31658253,-0.097631067 1.70710683,0.292893231 Z" id="Vector"></path>
            </g>
        </g>
    </svg>
);

export default closeIcon;
