import { FC } from 'react';
import { getOriginName } from '../../../../utils/utility';
import AddOfferToCartButton from '../../AddOfferToCartButton/AddOfferToCartButton';
import { OfferCardI } from '../OfferCardInterface';
import Link from '../../../Atoms/Link/Link';
import { handleCtaCopy } from '.././OfferCardUtils';
import { eventActions } from '../../../../utils/constants';


const OfferCardAction: FC<OfferCardI> = ({ offer, isOfferInCart, isCheckout }
) => {
    return (
        <div className="calls-to-action">
            {offer?.isShellOffer ?
                <Link config='button' color='black' to={`${getOriginName()}/shop/offers/detail/${offer?.id}/#getAFreeQuote`} track={{ action: 'get a free quote', container: eventActions.full }}>
                    {handleCtaCopy(isOfferInCart, offer, isCheckout)}</Link>
                : <AddOfferToCartButton isOfferInCart={isOfferInCart} offer={offer} isCheckout={isCheckout} />
            }
        </div>
    )
}
export default OfferCardAction;